import inMemoryJWT from './inMemoryJWT';
import { httpClient } from './dataProvider';

const apiUrl = process.env.REACT_APP_API_URL;
const apiAuth = process.env.REACT_APP_API_AUTH;

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(`${apiAuth}/login`, {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        inMemoryJWT.setRefreshTokenEndpoint(`${apiAuth}/refresh-token-admin`);
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ tokens, user }) => {
                if (user) {
                    localStorage.setItem('permissions', user.role);
                }
                return inMemoryJWT.setToken(tokens.accessToken);
            });
    },

    logout: () => {
        const request = new Request(`${apiAuth}/logout`, {
            method: 'POST',
            body: JSON.stringify({ refreshToken: inMemoryJWT.getToken() }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        inMemoryJWT.ereaseToken();

        return fetch(request).then(() => '/login').catch(() => '/login');
    },

    checkAuth: () => {
        // TODO: Implement checkAuth
        return Promise.resolve();
        /* console.log('[authProvider] checkAuth');
        return httpClient(`${apiAuth}/../profile`)
            .then(() => Promise.resolve())
            .catch(() => Promise.reject()); */
    },

    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            inMemoryJWT.ereaseToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        // return Promise.resolve('admin');
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    },
};

export default authProvider;