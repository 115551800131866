import { useEffect, useState } from "react";
import { TimePeriod } from "../interfaces/timePeriod.interface";

export default function GetTimePeriodRange(
  id: number,
  timePeriods: { [key: number]: TimePeriod }
): string {
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>("");

  useEffect(() => {
    if (id && timePeriods) {
      const selectedDate = Object.values(timePeriods).find(
        (period) => period.id === id
      );
      if (selectedDate?.start) {
        const startDate = new Intl.DateTimeFormat("sv-SE", {
          day: "2-digit",
          month: "short",
        }).format(new Date(selectedDate?.start));
        const endDate = new Intl.DateTimeFormat("sv-SE", {
          day: "2-digit",
          month: "short",
        }).format(new Date(selectedDate?.end));
        const selectedPeriod = `${startDate} - ${endDate}`;
        setSelectedTimePeriod(selectedPeriod);
      } else {
        setSelectedTimePeriod("");
      }
    } else {
      setSelectedTimePeriod("");
    }
  }, [id, timePeriods]);
  return selectedTimePeriod;
}
