import { MapConnection } from "../enums/map-connection.enum";
import { Recurrence } from "../enums/recurrence.enum";

export const validateInformation = (values) => {
  const errors: any = {};
  if (values.buttonText && !values.buttonUrl) {
    errors.buttonUrl = "Button URL can't be empty if text is filled in.";
  }
  if (new Date(values.startAt).getTime() > new Date(values.endAt).getTime()) {
    errors.startAt = `"start at" can't be after "end at"`;
  }
  if (new Date(values.endAt).getTime() < new Date(values.startAt).getTime()) {
    errors.endAt = `"end at" can't be before "start at"`;
  }
  if (
    values.mapConnection === MapConnection.PointOfInterest &&
    values.pointOfInterestId &&
    !values.radius
  ) {
    errors.radius = `Radius need to be filled if you choose to use a Point of Interest`;
  }
  if (
    values.mapConnection === MapConnection.PointOfInterest &&
    !values.pointOfInterestId
  ) {
    errors.pointOfInterestId = `Point of Interest can't be empty when Map connection is "Point of Interest"`;
  }
  if (
    values.mapConnection === MapConnection.AreaOfInterest &&
    !values.areaOfInterestId
  ) {
    errors.areaOfInterestId = `Area of Interest can't be empty when Map connection is "Area of Interest"`;
  }
  if (
    values.mapConnection === MapConnection.None &&
    values.recurrence === Recurrence.OnEntrance
  ) {
    errors.recurrence = `Recurrence can't be "On Entrance" with no map connection.`;
  }

  // NOTE: We do not force button text even if the URL is filled in?
  /* if (!values.buttonText && values.buttonUrl) {
          errors.buttonText = 'Button Text can\'t be empty if URL is filled in.';
      } */
  return errors;
};
